<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="isDataLoadedFromServer"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Add New Notification</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div class="h-75px">
          <v-select
            v-model="formData.customer_id"
            label="Customer"
            item-text="text"
            item-value="index"
            :items="serverData.clients"
            clearable
            outlined
            dense
            required
            :error-messages="customer_idErrors"
            @input="$v.formData.customer_id.$touch()"
            @blur="$v.formData.customer_id.$touch()"
          />
        </div>
        <div class="h-75px">
          <v-select
            v-model="formData.action_id"
            label="Action"
            item-text="text"
            item-value="index"
            :items="serverData.actions"
            clearable
            outlined
            dense
            :error-messages="action_idErrors"
            @input="$v.formData.action_id.$touch()"
            @blur="$v.formData.action_id.$touch()"
          />
        </div>
        <div class="h-75px">
          <v-select
            v-model="formData.order_status_id"
            label="Order Status"
            item-text="text"
            item-value="index"
            :items="serverData.order_statuses"
            clearable
            outlined
            dense
            :error-messages="order_status_idErrors"
            @input="$v.formData.order_status_id.$touch()"
            @blur="$v.formData.order_status_id.$touch()"
          />
        </div>
        <div class="h-75px">
          <v-text-field
            v-model="formData.name"
            label="Name"
            clearable
            outlined
            dense
            :error-messages="nameErrors"
            @input="$v.formData.name.$touch()"
            @blur="$v.formData.name.$touch()"
          />
        </div>

        <CopyItemList
          :dynamic_variables="serverData.dynamic_variables"
          :insert-text="insertText"
        />

        <ckeditor v-model="formData.message" ref="ckeditor"></ckeditor>

        <div class="v-text-field__details" v-if="messageErrors.length > 0">
          <span class="error--text mt-2"> {{ messageErrors[0] }}</span>
        </div>
        <!--end::Body-->
        <div class="mb-4 mt-6 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import CopyItemList from "@/own/components/shipping/customerNotifications/CopyItemList";

export default {
  mixins: [validationMixin],
  components: {
    CopyItemList,
  },
  validations: {
    formData: {
      customer_id: { required },
      action_id: { required },
      order_status_id: { required },
      name: { required },
      message: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    carrier: null,
    formData: {
      customer_id: null,
      action_id: null,
      order_status_id: null,
      name: null,
      message: null,
    },
  }),
  methods: {
    insertText(text) {
      this.$refs.ckeditor.instance.insertText(text);
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/shipping/end_customer_notifications/create")
        .then((response) => {
          this.serverData = response.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    toggleModal() {
      if (this.dialog) this.resetCreateForm();
      else this.loadDataFromServer();
      this.dialog = !this.dialog;
    },
    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if ((item[1] && item[0] !== "image") || item[1] === 0) {
          data.append(item[0], item[1]);
        } else if (item[1] && item[0] === "image") {
          data.append(item[0], item[1][0]);
        }
      });
      return data;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        let data = this.convertToFormData();
        ApiService.post("/shipping/end_customer_notifications/store", data)
          .then(() => {
            this.pageLoader(false);
            swalAdded();
            this.toggleModal();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        customer_id: null,
        action_id: null,
        order_status_id: null,
        name: null,
        message: null,
      };
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    customer_idErrors() {
      return this.handleFormValidation("customer_id", this);
    },
    action_idErrors() {
      return this.handleFormValidation("action_id", this);
    },
    order_status_idErrors() {
      return this.handleFormValidation("order_status_id", this);
    },
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    messageErrors() {
      return this.handleFormValidation("message", this);
    },
    selected_action() {
      return this.formData.action_id;
    },
  },
  watch: {
    selected_action(val) {
      let selectedItem = null;
      if (val)
        selectedItem = this.serverData.actions.filter(
          (item) => item.index === val
        )[0].template.mail;
      this.formData.message = selectedItem;
    },
  },
};
</script>
