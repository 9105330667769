<template>
  <v-row class="ma-0">
    <v-col cols="12" class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
      <DataTable
        :table_state="table_state"
        :store_names="store_names"
        :addComponent="componentForAdding"
        :editComponent="componentForEditing"
        :delete_url="delete_url"
        addButtonText="New Notification"
      ></DataTable>
    </v-col>
  </v-row>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import AddItem from "@/own/components/shipping/customerNotifications/AddItem.vue";
import EditItem from "@/own/components/shipping/customerNotifications/EditItem";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/customerNotifications.module";
export default {
  name: "CustomerNotifications",
  components: { DataTable },
  data: () => ({
    componentForEditing: EditItem,
    componentForAdding: AddItem,
    delete_url: `${process.env.VUE_APP_BASE_URL}/shipping/end_customer_notifications/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getCUSTOMERNOTIFICATIONSTableFilters",
      getTableState: "getCUSTOMERNOTIFICATIONSTableState",
      getTableData: "getCUSTOMERNOTIFICATIONSTableData",
      getTableHeaders: "getCUSTOMERNOTIFICATIONSTableHeaders",
      getTableProperties: "getCUSTOMERNOTIFICATIONSTableProperties",
      getTableExportUrl: "getCUSTOMERNOTIFICATIONSExportUrl",
      getTablePermissions: "getCUSTOMERNOTIFICATIONSTablePermissions",
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
