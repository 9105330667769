<template>
  <div v-if="dynamic_variables">
    <h5>Dynamic Variables:</h5>
    <div class="d-flex mb-4">
      <div
        v-for="(item, index) in dynamic_variables"
        :key="index"
        class="py-2 mr-5"
      >
        <CopyItem :item="item" :insert="insertText" />
      </div>
    </div>
  </div>
</template>

<script>
import CopyItem from "@/own/components/shipping/customerNotifications/CopyItem";

export default {
  props: ["dynamic_variables", "insertText"],
  components: {
    CopyItem,
  },
};
</script>
